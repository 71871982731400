<template>
  <div class="back">
    <!-- <div class="title">合力官网{{ $parent.switchLanguage }}</div> -->
    <div class="title">
      <span v-if="$parent.switchLanguage == 'zh'">
        {{
          $parent.switchLanguage == "zh"
            ? "公司特色"
            : "Company Characteristics"
        }}
        <div class="menu"></div>
      </span>
      <p v-if="$parent.switchLanguage == 'zh'">
        规模化生产、自动化与智能化的生产线、完整的产业链条持续的技术革新、数字化转型等多方面措施
      </p>
    </div>
    <div class="content">
        <img v-if="$parent.switchLanguage == 'zh'" src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/characteristic%2FimgZh.png" alt="">
        <img v-if="$parent.switchLanguage == 'en'" src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/characteristic%2FimgCh.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "regards",
  data() {
    return {
      switchLanguage: "",
    //   list: [
    //     {
    //       img: require("https://website-1308146035.cos.ap-shanghai.myqcloud.com/characteristic%2Fimg1.png"),
    //       title: "1、LED光源工厂规模化优势",
    //       content:
    //         "公司拥有庞大的生产能力，包括30条LEDT管生H90条LED球泡灯生产线，能年产1亿只T曾0.05%及2亿只球泡灯系列，这样的规模化生产有助于摊薄固定成本降低单位产品成本，提高价格争力。规模化带动的市场响应速度能够快速响应市场需求，灵活调整生产计划，快速推出新产品，增强了市场适应性和竞争力。",
    //     },
    //     {
    //       img: require("https://website-1308146035.cos.ap-shanghai.myqcloud.com/characteristic%2Fimg2.png"),
    //       title: "2、持续追求LED生产线的自动化与智能化",
    //       content:
    //         "从2019年起，公司投入球泡灯全自动生产线并持续改进至2023年已形成成熟的全自动生产线体系，极大提高了生产效率与产能稳定性公司每年固定用净利润的30%用于现有设备的维护和改造升级。LED球泡车间已全面实现用工数1人/条生产线:LEDT管车间已全面实现最高时速生产线24000只灯管/天",
    //     },
    //     {
    //       img: require("https://website-1308146035.cos.ap-shanghai.myqcloud.com/characteristic%2Fimg3.png"),
    //       title: "3、完整的LED全产业链布局",
    //       content:
    //         "公司拥有庞大的生产能力，包括30条LEDT管生H90条LED球泡灯生产线，能年产1亿只T曾0.05%及2亿只球泡灯系列，这样的规模化生产有助于摊薄固定成本降低单位产品成本，提高价格争力。规模化带动的市场响应速度能够快速响应市场需求，灵活调整生产计划，快速推出新产品，增强了市场适应性和竞争力。",
    //     },
    //     {
    //       img: require("https://website-1308146035.cos.ap-shanghai.myqcloud.com/characteristic%2Fimg4.png"),
    //       title: "4、持续追求LED生产线的自动化与智能化",
    //       content:
    //         "从2019年起，公司投入球泡灯全自动生产线并持续改进至2023年已形成成熟的全自动生产线体系，极大提高了生产效率与产能稳定性公司每年固定用净利润的30%用于现有设备的维护和改造升级。LED球泡车间已全面实现用工数1人/条生产线:LEDT管车间已全面实现最高时速生产线24000只灯管/天",
    //     },
    //     {
    //       img: require("https://website-1308146035.cos.ap-shanghai.myqcloud.com/characteristic%2Fimg5.png"),
    //       title: "5、LED光源工厂规模化优势",
    //       content:
    //         "公司拥有庞大的生产能力，包括30条LEDT管生H90条LED球泡灯生产线，能年产1亿只T曾0.05%及2亿只球泡灯系列，这样的规模化生产有助于摊薄固定成本降低单位产品成本，提高价格争力。规模化带动的市场响应速度能够快速响应市场需求，灵活调整生产计划，快速推出新产品，增强了市场适应性和竞争力。",
    //     },
    //     {
    //       img: require("https://website-1308146035.cos.ap-shanghai.myqcloud.com/characteristic%2Fimg5.png"),
    //       title: "6、持续追求LED生产线的自动化与智能化",
    //       content:
    //         "从2019年起，公司投入球泡灯全自动生产线并持续改进至2023年已形成成熟的全自动生产线体系，极大提高了生产效率与产能稳定性公司每年固定用净利润的30%用于现有设备的维护和改造升级。LED球泡车间已全面实现用工数1人/条生产线:LEDT管车间已全面实现最高时速生产线24000只灯管/天",
    //     },
    //   ],
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  padding: 50px 0;
  span {
    position: relative;
  }
  .menu {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 90px;
    height: 6px;
    background: #96c33b;
    z-index: -1;
  }
  p {
    font-size: 14px;
    color: #666;
    margin-top: 10px;
    font-weight: normal;
  }
}
.content{
    width: 100%;
    height: 2440px;
    padding: 0 10% 10%;
    img{
        width: 100%;
        height: 100%;
    }
}
</style>
